import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import toast from 'react-hot-toast';

import { fetchFreeSlots } from '@/services/fetchFreeSlots';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';

export const useFreeSlots = () => {
  const { userSelection, freeSlots, setFreeSlots } = useAppointmentsStore();

  const shouldFetchSlots = useMemo(
    () => Boolean(userSelection?.provider?.id && userSelection?.appointmentType !== null),
    [userSelection],
  );

  const { isFetching, error, data } = useQuery({
    queryKey: [
      'freeSlots',
      {
        providerId: userSelection?.provider?.id ?? null,
        appointmentType: userSelection?.appointmentType || null,
      },
    ],
    queryFn: () => fetchFreeSlots(userSelection?.provider, userSelection?.appointmentType),
    enabled: shouldFetchSlots,
    staleTime: 10000,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setFreeSlots(data?.freeSlots ?? null);
  }, [data?.freeSlots, setFreeSlots]);

  useEffect(() => {
    if (data?.error) {
      toast.error(data?.error.description);
    }
  }, [data?.error]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  return {
    isFetching,
    freeSlots,
  };
};
