import 'react-day-picker/dist/style.css';
import './DoctorFreeSlots.css';

import { useMutation } from '@tanstack/react-query';
import { isSameDay, max, min } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import toast from 'react-hot-toast';

import { BackButton } from '@/components/BackButton';
import { Button } from '@/components/Button';
import { Loader } from '@/components/Loader';
import { SelectorContainer } from '@/components/SelectorContainer';
import { getTimeSlotWithoutTimezone } from '@/lib/helpers/slots';
import { TimeSlotSelector } from '@/modules/TimeSlotSelector';
import { createAppointment } from '@/services/createAppointment';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';
import { MeetingDay } from '@/types/meetings.types';

import { ConfirmationScreen } from '../ConfirmationScreen';

interface DoctorFreeSlotsProps {
  goBack: () => void;
}

export const DoctorFreeSlots = ({ goBack }: DoctorFreeSlotsProps) => {
  const {
    userSelection: {
      provider: selectedProvider,
      appointmentType: selectedAppointmentType,
      meeting: selectedMeeting,
    },
    freeSlots,
    clearSelectedMeeting,
  } = useAppointmentsStore();

  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const [meetingDay, setMeetingDay] = useState<MeetingDay | null>(null);
  const [showCalendar, setShowCalendar] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const {
    isPending,
    error,
    data: newAppointment,
    mutate,
  } = useMutation({
    mutationFn: () =>
      createAppointment(selectedProvider, selectedAppointmentType, selectedMeeting, freeSlots),
  });

  const dateSlots = useMemo(
    () => freeSlots?.slots?.map((slot) => getTimeSlotWithoutTimezone(slot.time)) ?? [],
    [freeSlots?.slots],
  );
  const availableDates = useMemo(
    () =>
      dateSlots
        .filter((date, i, self) => self.findIndex((d) => isSameDay(d, date)) === i) // get unique dates
        .filter((day) => !isSameDay(new Date(), day)), // remove today's slots
    [dateSlots],
  );

  useEffect(() => {
    if (selectedDate) {
      const slotDay = availableDates
        .map((day) => ({
          date: day,
          slots: dateSlots.filter((slot) => isSameDay(slot, day)).map((slot) => ({ date: slot })),
        }))
        .find((slot) => isSameDay(slot.date, selectedDate));

      setMeetingDay(slotDay ?? null);
      clearSelectedMeeting();
      setShowCalendar(false);
    }
  }, [selectedDate, dateSlots, availableDates, clearSelectedMeeting]);

  const closeFoldOut = () => {
    setTimeout(() => {
      window.parent.postMessage(JSON.stringify({action: 'foldOutClose', data: {type: 'iframe'}}), '*')
    }, 7500);
  }

  useEffect(() => {
    if (newAppointment?.confirmed) {
      setShowConfirmation(true);
      clearSelectedMeeting();
      closeFoldOut();
    }
  }, [newAppointment, clearSelectedMeeting]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (newAppointment?.error) {
      toast.error(newAppointment?.error.description);
    }
  }, [newAppointment?.error]);

  const onSelect = (day: Date | undefined) => {
    setSelectedDate(day);
  };

  const handleBack = () => {
    if (showCalendar) {
      goBack();
      return;
    }

    if (selectedDate && !showCalendar) {
      setShowCalendar(true);
      return;
    }
  };

  const handleConfirmation = () => {
    mutate();
  };

  if (!freeSlots) {
    return;
  }

  if (showConfirmation) {
    return <ConfirmationScreen appointmentDescription={newAppointment?.description} />;
  }

  return (
    <SelectorContainer text="Choose a time slot that best suits you.">
      {showCalendar && (
        <DayPicker
          mode="single"
          selected={selectedDate}
          onSelect={onSelect}
          fromDate={min(availableDates)}
          toDate={max(availableDates)}
          disabled={(day) => !availableDates.some((d) => isSameDay(d, day))}
        />
      )}

      {selectedDate && !showCalendar && (
        <div className="time-slot__container">
          <TimeSlotSelector selectedDate={selectedDate} meetingDay={meetingDay} />
        </div>
      )}

      <div className="time-slot__buttons-container">
        {isPending ? (
          <Loader />
        ) : (
          <>
            <BackButton onClick={handleBack} />
            <Button
              onClick={handleConfirmation}
              isActive={!!selectedMeeting}
              disabled={!selectedMeeting}
            >
              Confirm appointment
            </Button>
          </>
        )}
      </div>
    </SelectorContainer>
  );
};
