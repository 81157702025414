import './BackButton.css';

import classNames from 'classnames';

import { ReactComponent as ArrowLeft } from '@/assets/arrow-left.svg';
import { Button } from '@/components/Button';

type BackButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  onClick: () => void;
};

export const BackButton = ({ className, onClick }: BackButtonProps) => {
  return (
    <Button className={classNames('btn btn--active back-btn', className)} onClick={onClick}>
      <ArrowLeft />
    </Button>
  );
};
