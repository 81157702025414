import { createDearDocAppointment } from '@/lib/api';
import { slotsAreEqual } from '@/lib/helpers/slots';
import {
  getLocationIdFromUrl,
  getPatientInfoFromQueryParams,
  hasAllInfoFromQuery,
} from '@/lib/helpers/urlParams';
import {
  DearDocAppointment,
  DearDocFreeSlots,
  DearDocProvider,
  DearDocSlot,
} from '@/types/api.types';
import { MeetingSlot } from '@/types/meetings.types';

type CreateAppointmentResponse = Promise<{
  error?: Record<string, string> | null;
  confirmed: boolean;
  description?: string;
  data?: unknown;
}>;

export const createAppointment = async (
  provider: DearDocProvider | null,
  selectedAppointmentType: string | null,
  meeting: MeetingSlot | null,
  freeSlots: DearDocFreeSlots | null,
): CreateAppointmentResponse => {
  try {
    if (!hasAllInfoFromQuery()) {
      return {
        error: {
          title: 'Missing query params',
          description:
            'Please provide all the required query params: name, email, phone and location',
        },
        confirmed: false,
      };
    }

    if (!provider) {
      return {
        error: {
          title: 'Missing provider ID',
          description: 'Please select a valid provider for the appointment',
        },
        confirmed: false,
      };
    }

    if (!meeting) {
      return {
        error: {
          title: 'Missing meeting',
          description: 'Please select a meeting slot',
        },
        confirmed: false,
      };
    }

    if (!freeSlots) {
      return {
        error: {
          title: 'Missing slots',
          description: 'Please provide a valid list of slots',
        },
        confirmed: false,
      };
    }

    const meetingDate = meeting.date;
    const originalSlot = freeSlots.slots.find((slot: DearDocSlot) =>
      slotsAreEqual(slot.time, meetingDate),
    );
    if (!originalSlot) {
      return {
        error: {
          title: 'No original slot found',
          description: 'Please select a valid time slot from the given options',
        },
        confirmed: false,
      };
    }

    const patientInfo = getPatientInfoFromQueryParams();

    const appointmentPayload: DearDocAppointment = {
      patient_name: patientInfo.name!,
      patient_email: patientInfo.email!,
      patient_phone: patientInfo.phone!,
      slot: originalSlot.time,
    };

    if (selectedAppointmentType) {
      appointmentPayload.appointment_type = selectedAppointmentType;
    }

    if (originalSlot.operatory_id) {
      appointmentPayload.operatory_id = originalSlot.operatory_id;
    }

    const locationId = getLocationIdFromUrl()!;
    const { error } = await createDearDocAppointment(locationId, provider.id, appointmentPayload);

    if (error) {
      console.error(error);
      return {
        error: {
          title: 'Error creating assignment',
          description: error,
        },
        confirmed: false,
      };
    }

    return {
      error: null,
      confirmed: true,
      description: `Your appointment has been confirmed for ${meetingDate.toLocaleString(
        'en-US',
      )} with Dr. ${provider.name}`,
    };
  } catch (e) {
    console.error(e);

    return {
      error: {
        title: "Sorry, something didn't worked as expected",
        description: 'The appointment manager is not available right now. Please try again later.',
      },
      confirmed: false,
    };
  }
};
