const formatDate = (date: Date): string => {
  const dateWithoutTimezone = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
  // Convert date to ISO string and remove Z
  const isoString = dateWithoutTimezone.toISOString().slice(0, -1);
  // Get timezone offset in minutes
  const timezoneOffset = date.getTimezoneOffset();
  // Convert timezone offset to hours and minutes
  const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset) / 60);
  const timezoneOffsetMinutes = Math.abs(timezoneOffset) % 60;

  // Format timezone offset as string
  const timezoneOffsetString =
    (timezoneOffset < 0 ? '+' : '-') +
    String(timezoneOffsetHours).padStart(2, '0') +
    ':' +
    String(timezoneOffsetMinutes).padStart(2, '0');
  // Append timezone offset to ISO string
  return isoString + timezoneOffsetString;
};

const slotToDate = (slot: string | Date): Date => {
  if (slot instanceof Date) {
    const stringDate = formatDate(slot);
    return new Date(stringDate.substring(0, 23));
  } else {
    return new Date(slot.substring(0, 23));
  }
};

export const slotsAreEqual = (oneSlot: string | Date, anotherSlot: string | Date): boolean => {
  const dateOne = slotToDate(oneSlot);
  const dateTwo = slotToDate(anotherSlot);

  return dateOne.getTime() === dateTwo.getTime();
};

export const getTimeSlotWithoutTimezone = (slot: Date | string): Date => {
  if (slot instanceof Date) {
    return slot;
  }

  return new Date(slot.substring(0, 23));
};

export const formatTime = (time: Date | string): string =>
  time.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
