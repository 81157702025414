import './TimeSlotSelector.css';

import { format } from 'date-fns';
import { useState } from 'react';

import { Button } from '@/components/Button';
import { formatTime, slotsAreEqual } from '@/lib/helpers/slots';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';
import { MeetingDay, MeetingSlot } from '@/types/meetings.types';

interface TimeSlotSelectorProps {
  selectedDate: Date;
  meetingDay: MeetingDay | null;
}

export const TimeSlotSelector = ({ selectedDate, meetingDay }: TimeSlotSelectorProps) => {
  const { onMeetingSelected } = useAppointmentsStore();
  const [selectedSlot, setSelectedSlot] = useState<MeetingSlot | null>(null);

  const handleClick = (slot: MeetingSlot) => {
    const newSlot = selectedSlot?.date && slotsAreEqual(slot.date, selectedSlot.date) ? null : slot;
    setSelectedSlot(newSlot);
    onMeetingSelected(newSlot);
  };

  const formattedDate = format(selectedDate, 'PP');

  return (
    <>
      <span>
        Selected date: <b>{formattedDate}</b>
      </span>

      <div className="meeting-slots__grid">
        {meetingDay?.slots.map((slot) => {
          const formattedTime = formatTime(slot.date);

          return (
            <Button
              key={`${formattedDate} - ${formattedTime}`}
              className="meeting-slot"
              isActive={Boolean(selectedSlot?.date && slotsAreEqual(slot.date, selectedSlot.date))}
              onClick={() => handleClick(slot)}
              data-testid="meeting-slot"
            >
              {formattedTime}
            </Button>
          );
        })}
      </div>
    </>
  );
};
