import { getDearDocProviders } from '@/lib/api';
import { getLocationIdFromUrl, hasAllInfoFromQuery } from '@/lib/helpers/urlParams';
import { DearDocProvider } from '@/types/api.types';

type FetchProvidersResponse = Promise<{
  error?: Record<string, string> | null;
  providers: DearDocProvider[];
}>;

export const fetchProviders = async (): FetchProvidersResponse => {
  try {
    if (!hasAllInfoFromQuery()) {
      return {
        error: {
          title: 'Missing query params',
          description:
            'Please provide all the required query params: name, email, phone and location',
        },
        providers: [],
      };
    }

    const locationId = getLocationIdFromUrl()!;
    const providers = await getDearDocProviders(locationId);

    return { providers, error: null };
  } catch (e) {
    console.error(e);

    return {
      error: {
        title: "Sorry, something didn't worked as expected",
        description: 'The appointment manager is not available right now. Please try again later.',
      },
      providers: [],
    };
  }
};
