import { getDearDocFreeSlots } from '@/lib/api';
import { getLocationIdFromUrl } from '@/lib/helpers/urlParams';
import { DearDocFreeSlots, DearDocProvider } from '@/types/api.types';

type FetchFreeSlotsResponse = Promise<{
  error?: Record<string, string> | null;
  freeSlots: DearDocFreeSlots | null;
}>;

export const fetchFreeSlots = async (
  provider: DearDocProvider | null,
  appointmentType: string | null,
): FetchFreeSlotsResponse => {
  try {
    if (!provider) {
      return {
        error: {
          title: 'Missing provider',
          description: 'Please include a valid provider',
        },
        freeSlots: null,
      };
    }

    const locationId = getLocationIdFromUrl()!;
    const freeSlotsFromProvider = await getDearDocFreeSlots(
      locationId,
      provider.id,
      appointmentType,
    );

    if (freeSlotsFromProvider?.slots && freeSlotsFromProvider.slots.length > 0) {
      return {
        freeSlots: freeSlotsFromProvider,
      };
    }

    return {
      freeSlots: null,
    };
  } catch (e) {
    console.error(e);

    return {
      error: {
        title: "Sorry, something didn't worked as expected",
        description: 'The appointment manager is not available right now. Please try again later.',
      },
      freeSlots: null,
    };
  }
};
