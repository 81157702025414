import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { fetchProviders } from '@/services/fetchProviders';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';

export const useProviders = () => {
  const { providers, setProviders } = useAppointmentsStore();

  const { isFetching, data, error } = useQuery({
    queryKey: ['providers'],
    queryFn: fetchProviders,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data?.providers) {
      setProviders(data.providers);
    }
  }, [data, setProviders]);

  useEffect(() => {
    if (data?.error) {
      toast.error(data?.error.description);
    }
  }, [data?.error]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  return {
    isFetching,
    providers,
  };
};
