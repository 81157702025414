export const getLocationIdFromUrl = (): string | null => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('location');
};

export const getPatientInfoFromQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return {
    name: urlParams.get('name'),
    email: urlParams.get('email'),
    phone: urlParams.get('phone'),
  };
};

export const hasAllInfoFromQuery = (): boolean => {
  const urlParams = new URLSearchParams(window.location.search);
  return (
    !!urlParams.get('location') &&
    !!urlParams.get('name') &&
    !!urlParams.get('email') &&
    !!urlParams.get('phone')
  );
};
