import { Button } from '@/components/Button';
import { SelectorContainer } from '@/components/SelectorContainer';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';

export const AppointmentTypeSelector = () => {
  const {
    userSelection: { provider: selectedProvider, appointmentType: selectedAppointmentType },
    onAppointmentTypeSelected,
  } = useAppointmentsStore();

  const appointmentTypes = selectedProvider?.appointment_types ?? [];
  const hasAppointments = appointmentTypes.length > 0;

  const handleClick = (type: string) => {
    const newType = selectedAppointmentType === type ? null : type;
    onAppointmentTypeSelected(newType, selectedProvider?.operatories ?? []);
  };

  if (!selectedProvider || !hasAppointments) {
    return null;
  }

  return (
    <SelectorContainer text="Select an appointment type:">
      {appointmentTypes.map(({ id, name }) => (
        <Button
          key={`appointmentType-${id}`}
          isActive={id === selectedAppointmentType}
          onClick={() => handleClick(id)}
          data-testid="appointment-slot"
        >
          {name}
        </Button>
      ))}
    </SelectorContainer>
  );
};
