import './ConfirmationScreen.css';

import { ReactComponent as Checkmark } from '@/assets/checkmark.svg';

interface ConfirmationScreenProps {
  appointmentDescription?: string;
}

export const ConfirmationScreen = ({ appointmentDescription }: ConfirmationScreenProps) => {
  return (
    <section className="confirmation__screen">
      <header className="confirmation__header">
        <div className="checkmark__container">
          <Checkmark className="checkmark__icon" />
        </div>
        <h1 className="confirmation__title">Appointment confirmed</h1>
      </header>
      <p>{appointmentDescription}</p>
      <p>You can continue safely.</p>
    </section>
  );
};
