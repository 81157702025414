import { Button } from '@/components/Button';
import { SelectorContainer } from '@/components/SelectorContainer';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';

export const DoctorSelector = () => {
  const {
    providers,
    userSelection: { provider: selectedProvider },
    onDoctorSelected,
  } = useAppointmentsStore();

  const handleClick = (id: string) => {
    const newId = selectedProvider?.id === id ? '' : id;
    onDoctorSelected(newId);
  };

  return (
    <SelectorContainer text="Please start by selecting a doctor:" data-testid="providers">
      {providers &&
        providers.length > 0 &&
        providers.map(({ id, name }) => (
          <Button
            key={`doctor-${id}`}
            isActive={id === selectedProvider?.id}
            onClick={() => handleClick(id)}
            data-testid="doctor-slot"
          >
            {name}
          </Button>
        ))}
    </SelectorContainer>
  );
};
