import './BookingPage.css';

import { useEffect, useState } from 'react';

import { Loader } from '@/components/Loader';
import { useFreeSlots } from '@/hooks/useFreeSlots';
import { useProviders } from '@/hooks/useProviders';
import { AppointmentTypeSelector } from '@/modules/AppointmentTypeSelector';
import { DoctorFreeSlots } from '@/modules/DoctorFreeSlots';
import { DoctorSelector } from '@/modules/DoctorSelector';
import { useAppointmentsStore } from '@/store/useAppointmentsStore';

export const BookingPage = () => {
  const [showTimePicker, setShowTimePicker] = useState(false);
  const { isFetching: isFetchingProviders, providers } = useProviders();
  const { isFetching: isFetchingFreeSlots, freeSlots } = useFreeSlots();
  const { onAppointmentTypeSelected } = useAppointmentsStore();

  useEffect(() => {
    setShowTimePicker(!isFetchingFreeSlots && !!freeSlots);
  }, [isFetchingFreeSlots, freeSlots]);

  const hasProviders = providers.length > 0;

  if (isFetchingProviders) {
    return <Loader />;
  }

  if (!hasProviders) {
    return <span>No results found</span>;
  }

  const closeSlots = () => {
    setShowTimePicker(false);
    onAppointmentTypeSelected(null, []);
  };

  return (
    <>
      {!showTimePicker && (
        <section className="doctor-appointments__container">
          <DoctorSelector />
          <AppointmentTypeSelector />
          {isFetchingFreeSlots && <Loader />}
        </section>
      )}

      {showTimePicker && (
        <section className="datetime-selector__container">
          <DoctorFreeSlots goBack={closeSlots} />
        </section>
      )}
    </>
  );
};
