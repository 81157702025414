import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';

import { FallbackComponent } from '@/components/FallbackComponent';
import { BookingPage } from '@/pages/BookingPage/BookingPage';

function App() {
  const [queryClient] = useState(() => new QueryClient());

  return (
    <Sentry.ErrorBoundary fallback={() => <FallbackComponent />}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />

        <main className="container">
          <BookingPage />
        </main>

        <Toaster position="bottom-center" />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
