import './SelectorContainer.css';

interface SelectorContainerProps {
  text: string;
  children?: React.ReactNode;
}

export const SelectorContainer = ({ text, children }: SelectorContainerProps) => {
  return (
    <div className="selector-container">
      <p className="selector-text">{text}</p>

      <div className="selector-options">{children}</div>
    </div>
  );
};
